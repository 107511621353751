import React from 'react';

import { Navigate, Outlet } from 'react-router-dom';

export const PrivateRoute = () => {
  const authed = JSON.parse(localStorage.getItem('user')) !== null;
  if (!authed) {
    return <Navigate replace to={'/login'} />;
  }
  return <Outlet />;
};
