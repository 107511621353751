import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import CheckedIcon from '../../../assets/icons/check-done.svg';

const variants = {
  default: {
    width: '20px',
    height: '20px',
    scale: 0.7,
  },
  small: {
    width: '16px',
    height: '16px',
    scale: 0.5,
  },
};

const Checkbox = ({
  name,
  is_required = false,
  onChange = () => {},
  checked = false,
  error,
  is_rounded = false,
  className = '',
  inputClassname = '',
  is_checked_done = false,
  isStopPropagation = true,
  variant = 'default',
  labelClassname = '',
}) => {
  return (
    <CheckboxWrapper variant={variants[variant]} className={`flex items-center ${className}`}>
      <input
        className={classNames(
          'cursor checkbox radius-1',
          inputClassname,
          is_rounded && 'rounded',
          checked && !is_checked_done && 'checked',
          checked && is_checked_done && 'flex items-center justify-center checked-done',
          error && 'required-error',
        )}
        type="checkbox"
        checked={checked}
        onClick={e => {
          isStopPropagation && e.stopPropagation();
          onChange(!checked);
        }}
      />
      {name && (
        <div className="ml-2 flex-1">
          <span className={classNames('regular-text font-16 name-text', labelClassname)}>{name}</span>
          {is_required && <span className="required-star ml-1">*</span>}
        </div>
      )}
    </CheckboxWrapper>
  );
};

const CheckboxWrapper = styled('div')`
  .checkbox {
    appearance: none;
    min-width: ${({ variant }) => variant.width};
    min-height: ${({ variant }) => variant.height};
    outline: none;
    margin: 0;
    border: 1px solid ${({ theme }) => theme.natural_300};
    background-color: #fff;
  }

  .rounded {
    border-radius: 50%;
  }

  .checked {
    outline: ${({ theme }) => theme.primary_500} solid 6px;
    outline-offset: -12px;
  }

  .checked-done {
    background-color: ${({ theme }) => theme.primary_500};
    border-color: ${({ theme }) => theme.primary_500};
    position: relative;

    &:before {
      content: url(${CheckedIcon});
      position: absolute;
      transform: scale(${({ variant }) => variant.scale});
    }
  }

  .required-error {
    border-color: ${({ theme }) => theme.error_500};
  }
`;

export default Checkbox;
