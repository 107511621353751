import classNames from 'classnames';
import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as MasqueradeIcon } from '../../../assets/icons/masquerade.svg';
import { OrganisationContext } from '../../../context/organisationContext';

const ProfileMenuDropdown = ({ menuList = [], menuClassName = '', title = '' }) => {
  const { checkPermission } = useContext(OrganisationContext);

  const { user } = useSelector(state => state.user);
  const { selectedAccount } = user || {};

  const [showMenu, setShowMenu] = useState(false);

  const onBlur = e => {
    e.preventDefault();
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setShowMenu(false);
    }
  };

  const onMenuItemClick = menuItem => {
    const { onClick, permission } = menuItem;
    checkPermission(() => {
      onClick && onClick();
    }, permission);
  };

  return (
    <MenuWrapper
      className={classNames('relative', menuClassName)}
      onBlur={onBlur}
      tabIndex={0}
      onClick={e => {
        e.stopPropagation();
        setShowMenu(!showMenu);
      }}>
      <div
        className={classNames(
          'flex items-center justify-center ml-4 cursor nav-icon-wrapper user-logo-wrapper',
          showMenu && 'active-logo',
        )}>
        {user.isAccessAccount ? (
          <MasqueradeIcon className="white-text" />
        ) : (
          <label className="bold-text white-text">{title && title}</label>
        )}
      </div>

      {showMenu && menuList.length > 0 && (
        <div className="menu-wrapper">
          {user.isAccessAccount ? (
            <div className="flex items-center pxy-3-4 gap-2_5 user-details">
              <div className="flex items-center justify-center nav-icon-wrapper user-logo-wrapper">
                <MasqueradeIcon className="white-text" />
              </div>
              <div className="one-line profile-detail-user">
                <p className="text inter-400-text color-neutral-900 one-line">{selectedAccount?.name}</p>
              </div>
            </div>
          ) : (
            <div className="flex items-center pxy-3-4 gap-2_5 user-details">
              <div className="flex items-center justify-center nav-icon-wrapper user-logo-wrapper">
                <label className="bold-text white-text">{title && title}</label>
              </div>
              <div className="one-line profile-detail-user">
                <p className="text inter-400-text color-neutral-900 one-line">
                  {user?.user?.forename} {user?.user?.surname}
                </p>
                <span className="text inter-400-text natural-400-text">{user?.user?.email}</span>
              </div>
            </div>
          )}
          {menuList.map((menuItem, index) => (
            <div
              className="flex items-center cursor pxy-3-4 gap-2 menu-item"
              onClick={() => onMenuItemClick(menuItem)}
              key={index}>
              {menuItem.withIcon && <menuItem.icon height={20} width={20} />}
              <span className="regular-text">{menuItem.name}</span>
            </div>
          ))}
        </div>
      )}
    </MenuWrapper>
  );
};

const MenuWrapper = styled('div')`
  position: relative;

  .menu-btn {
    height: 32px;
    width: 32px;
    &:hover {
      background-color: ${({ theme }) => theme.lightGreyBackground};
      border-radius: 12px;
    }
  }

  .menu-wrapper {
    position: absolute;
    top: 64px;
    right: 0px;
    width: 260px;
    z-index: 10;
    background: #ffffff;
    box-shadow: 2px 8px 20px rgba(5, 49, 73, 0.14);
    border-radius: 6px;

    .user-details {
      border-bottom: 1px solid #e5e5e5;
    }

    .menu-item:hover {
      background-color: ${({ theme }) => theme.lightGreyBackground};
    }
    .menu-item:last-child {
      border-top: 1px solid #e5e5e5;
    }
  }
`;

export default ProfileMenuDropdown;
